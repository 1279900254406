import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { breakpoints, colors } from '../../style-utilities/variables';
// import components
import NewsGridItem from './NewsGridItem';

const StyledNewsGrid = styled.div`
  display: -ms-grid;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-row-gap: 1rem;
  @media (min-width: ${breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr;
    -ms-grid-columns: 1fr 2rem 1fr;
    -ms-grid-rows: 1fr 2rem 1fr 2rem 1fr;
    div:nth-child(odd) {
      -ms-grid-column: 1;
    }
    div:nth-child(even) {
      -ms-grid-column: 3;
    }
    div:nth-child(n + 3) {
      -ms-grid-row: 3;
    }
    div:nth-child(n + 5) {
      -ms-grid-row: 5;
    }
  }
  @media (min-width: ${breakpoints.tabletMax}) {
    grid-row-gap: 2rem;
    grid-column-gap: 2rem;
  }
`;

class NewsGrid extends Component {
  render() {
    const posts = this.props.posts;

    if (posts[0].node.type == 'press') {
      return (
        <StyledNewsGrid className="press-grid">
          {posts.slice(0, this.props.itemsToShow).map(({ node }, index) => {
            return (
              <NewsGridItem
                key={node.slug}
                item={node}
                className={'index-' + index}
              />
            );
          })}
        </StyledNewsGrid>
      );
    } else {
      return (
        <StyledNewsGrid className="insights-grid">
          {posts.map(({ node }, index) => {
            let className = '';
            if (posts.length === 1) {
              className = 'last';
            }
            return (
              <NewsGridItem
                key={node.slug}
                item={node}
                index={index}
                className={className}
              />
            );
          })}
        </StyledNewsGrid>
      );
    }
  }
}

export default NewsGrid;
