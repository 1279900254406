import React, { Fragment, Component } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import { colors, breakpoints, fonts } from '../style-utilities/variables';
import styled from 'styled-components';
import ReactHTMLParser from 'react-html-parser';
import { graphql } from 'gatsby';

import NewsGrid from '../components/News/NewsGrid';
import ArrowLeft from '../components/ArrowLeft';
import Arrow from '../components/Arrow';
import Hero from '../components/Hero';
import MediaContactWidget from '../components/MediaContactWidget';
import ContactBanner from '../components/ContactBanner';
import Pagination from '../components/Pagination';
import DefaultSharingImage from './../static/ansira-logo-sharing.png';
import ButtonGrey from '../components/ButtonGrey';
import InTheNewsPostWidget from '../components/FeaturedPressWidget';

const StyledNewsPosts = styled.main`
  margin: 0.75em auto;
  margin-top: 0;
  max-width: 1500px;
  .post-area {
    display: -ms-grid;
    display: grid;
    grid-template-columns: 1fr;

    grid-template-rows: auto;
    grid-row-gap: 2rem;
    @media (min-width: ${breakpoints.tablet}) {
      padding: 1rem 0;
      grid-template-columns: 2fr 1fr;
      grid-template-rows: auto;
      grid-column-gap: 2rem;
      -ms-grid-columns: 2fr 2rem 1fr;
      div:nth-child(1) {
        -ms-grid-column: 1;
      }
      div:nth-child(2) {
        -ms-grid-column: 3;
      }
      .btns-number-container {
        justify-content: flex-end;
      }
    }
  }
`;
const StyledFeaturedMediaBlock = styled.div`
  padding-bottom: 1em;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-row-gap: 2rem;
  .media-contact {
    @media (min-width: ${breakpoints.desktop}) {
      height: 18rem;
      grid-column-start: 3;
    }
  }
  @media (min-width: ${breakpoints.desktop}) {
    padding: 1rem 0;
    grid-template-columns: 1fr 1.1fr 1.1fr;
    grid-template-rows: auto;
    grid-column-gap: 2rem;
  }
`;
const StyledFeaturedRow = styled.div`
  border: 2px solid black;
  padding: 1rem;
  background: ${colors.white};
  background-size: cover;
  display: flex;
  align-items: center;
  #featured-title {
    font-size: 22px;
    font-family: 'daytona-bold';
    color: black;
    :hover {
      text-decoration: underline;
    }
  }
  a {
    text-transform: capitalize;
    text-decoration: none;
    color: black;
  }
  .date {
    margin-bottom: 0.8em;
  }
  @media (min-width: ${breakpoints.tabletMax}) {
    grid-column: 1 / 3;
  }
`;

class NewsPostsTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewPayload: null,
      isPreviewMode: false
    };
  }

  componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    /*
    Preview Mode -------------------------------------
    */
    if (params.get('preview')) {
      let id = params.get('id');
      let wpnonce = params.get('_wpnonce');
      let BASE_URL = `https://api.dev.ansira.io/wp-json/wp/v2`;

      fetch(`${BASE_URL}/pages/${id}/revisions/?_wpnonce=${wpnonce}`, {
        mode: 'cors',
        credentials: 'include'
      })
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          if (myJson) {
            this.setState({
              isPreviewMode: true,
              previewPayload: myJson[0]
            });
          }
        });
    }
    /*
    -------------------------------------------------
    */
  }

  render() {
    const { group: posts } = this.props.pathContext;
    const { pathContext } = this.props;
    // Here we capture the featured article, note that we need to just render one.
    const { featured_article } = pathContext.additionalContext;

    //reveting to use non filtered but leaving this for later use
    // const newsListsSelfRemoved = posts.filter(function(e) {
    //   return e.node.slug != featured_article[0].node.slug;
    // });

    //store these in variables because pagination loses the featured node after first page`
    const featuredTitle = featured_article[0].node.title;
    const featuredExcerpt = featured_article[0].node.acf.excerpt;
    const featureDate = featured_article[0].node.acf.display_date;
    const featureLink = featured_article[0].node.slug;

    // console.log('------------------DEBUG INFO: NEWS POST -------------------------');
    // console.log(featuredTitle);
    // console.log(featured_article[0].node);
    // console.log('---------------------------------------------------------------');

    //Prevent missing news image from breaking content
    const featuredImg =
      featured_article[0].node.better_featured_image ? featured_article[0].node.better_featured_image.source_url : false;
    const featuredImageNoBG = {
      backgroundImage:
        'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://via.placeholder.com/1900)'
    };
    const featuredImageBG = {
      backgroundImage:
        'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' +
        featuredImg +
        ')'
    };
    const featuredImage = featuredImg ? featuredImageBG : featuredImageNoBG;

    let data = this.props.data.wordpressPage;

    if (this.state.isPreviewMode) {
      data.acf = this.state.previewPayload.acf;
    }

    return (
      <StyledNewsPosts className="animate">
        <Helmet>
          {/* General tags */}
          <title>Company News and Press Room | Ansira</title>
          <meta
            name="description"
            content="See the latest company news from Ansira, the leading marketing technology and services firm."
          />
          <meta name="image" content={DefaultSharingImage} />

          {/* OpenGraph tags */}
          <meta property="og:url" content="/about/news" />
          <meta property="og:type" content="website" />

          <meta
            property="og:title"
            content="Company News and Press Room | Ansira"
          />
          <meta
            property="og:description"
            content="See the latest company news from Ansira, the leading marketing technology and services firm."
          />
          <meta property="og:image" content={DefaultSharingImage} />

          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content="https://twitter.com/ansira" />
          <meta
            name="twitter:title"
            content="Company News and Press Room | Ansira"
          />
          <meta
            name="twitter:description"
            content="See the latest company news from Ansira, the leading marketing technology and services firm."
          />
          <meta name="twitter:image" content={DefaultSharingImage} />
        </Helmet>
        <Hero
          headline={data.acf.hero_headline}
          sub={data.acf.hero_sub_headline}
        />
        <div className="page-container">
          <StyledFeaturedMediaBlock>
            <StyledFeaturedRow>
              <div>
                <p className="date">{featureDate}</p>
                <h2 id="featured-title">
                  <Link to={'about/news/' + featureLink}>
                    {ReactHTMLParser(featuredTitle)}
                  </Link>
                </h2>
                {ReactHTMLParser(featuredExcerpt)}
                <div>
                  <ButtonGrey
                    textLabel="READ MORE"
                    pageLink={'about/news/' + featureLink}
                  />
                </div>
              </div>
            </StyledFeaturedRow>
            <MediaContactWidget
              className="media-contact"
              bgcolor={colors.white}
              textcolor={colors.primary}
            />
          </StyledFeaturedMediaBlock>
        </div>
        <div className="page-container">
          <div className="post-area">
            <NewsGrid posts={posts} />
            <InTheNewsPostWidget />
          </div>
          <Pagination pathContext={pathContext} pagePath="about/news" />
          <ContactBanner
            headline={data.acf.contact_headline}
            cta={data.acf.contact_cta}
            url={data.acf.contact_url}
          />
        </div>
      </StyledNewsPosts>
    );
  }
}

export default NewsPostsTemplate;

export const query = graphql`
  query {
    allWordpressWpNews(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          wordpress_id
          type
          title
          slug
          content
          topic
          better_featured_image {
            source_url
          }
          date(formatString: "MMMM D, YYYY")
          acf {
            excerpt
            display_date
            featured_article
            popularity_num
          }
          topic
          vertical
          technology
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
            yoast_wpseo_canonical
          }
        }
      }
    }
    wordpressPage(slug: { eq: "news" }) {
      type
      acf {
        hero_headline
        hero_sub_headline
        contact_headline
        contact_cta
        contact_url
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
    allWordpressCategory {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }
    allWordpressWpTopic {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }
    allWordpressWpVertical {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }
  }
`;
